import {Injectable} from '@angular/core';
import {ICreativeRaw} from '../interface/ICreativeRaw';
import {AdUnitType} from '../../shared/enum/AdUnitType';
import {CreativeFormStateService} from './creative-form-state.service';
import {IAdUnitFormatAssetPair} from '../interface/IAdUnitFormatAssetPair';
import {FileUploadService} from '../../asset/service/file-upload.service';
import {AssetControlData, ICreativeFormGroup} from '../interface/ICreativeFormGroup';
import {IAsset} from '../../asset/interface/IAsset';
import {AssetService} from '../../asset/service/asset.service';
import {FormGroup} from '@angular/forms';
import {ImageDataService} from '../../shared/service/file-utils/image-data.service';
import {PresignedUrlPayload} from '../../asset/interface/PresignedUrlPayload';
import {firstValueFrom, Subject} from 'rxjs';
import {CreativeControlsService} from './creative-controls.service';

@Injectable({
  providedIn: 'root'
})
export class CreativeRawService {
  public loadingSubject = new Subject<boolean>();

  constructor(private creativeFormStateService: CreativeFormStateService, private fileUploadService: FileUploadService,
              private assetService: AssetService, private imageDataService: ImageDataService,
              private creativeControlsService: CreativeControlsService) {
  }

  public async getCreativeRaw(): Promise<ICreativeRaw> {
    if (this.shouldCreateSplitScreen()) {
      return this.getCreativeRawSplitScreen();
    } else {
      return this.getCreativeRawRegular();
    }
  }

  private shouldCreateSplitScreen(): boolean {
    const formGroup = this.creativeFormStateService.getFormGroup();
    return formGroup.controls.isSplitScreen.value && formGroup.controls.adUnit.value.type === AdUnitType.INTERSTITIAL ||
      formGroup.controls.adUnit.value.type === AdUnitType.REWARDED;
  }

  private async getCreativeRawSplitScreen(): Promise<ICreativeRaw> {
    const formGroup = this.creativeFormStateService.getFormGroup();
    let newAssets: IAdUnitFormatAssetPair[];
    if (this.fileUploadService.canUploadUsingPresignedUrls()) {
      newAssets = await this.createNewAssetsPresignedForSplit();
    } else {
      newAssets = await this.createNewAssetsForSplit();
    }
    const existingAssets = this.getExistingAssetsSplit();
    return this.getCreativeRawSplit(formGroup, newAssets, existingAssets);
  }

  private getCreativeRawSplit(formGroup: FormGroup<ICreativeFormGroup>,
                              newAssets: IAdUnitFormatAssetPair[], existingAssets: IAdUnitFormatAssetPair[]): ICreativeRaw {
    const formValue = formGroup.value;
    const productIds = formValue.splitScreenSections.map(section => section.product.id);
    return {
      category: formValue.adUnit.type,
      name: formValue.name,
      productId: productIds[0],
      adUnitId: formValue.adUnit.id,
      assetIds: existingAssets.concat(newAssets).map(el => el.assetId),
      matchedAssetsToFormats: existingAssets.concat(newAssets),
      description: '',
      incentiveText: '',
      useStaticForDefaultHtml: false,
      splitScreenProductIds: productIds,
      splitScreenMutedProductIds: formValue.splitScreenSections.filter(section => !section.withSound).map(section => section.product.id)
    };
  }

  private async createNewAssetsForSplit(): Promise<IAdUnitFormatAssetPair[]> {
    const assets: IAdUnitFormatAssetPair[] = [];
    for (const assetControl of this.getNewAssetsControlsSplit()) {
      try {
        const assetId = await this.createAsset(assetControl);
        assets.push({assetId, adUnitFormatId: assetControl.adUnitFormat.id});
      } catch (error) {
        this.loadingSubject.next(false);
        throw new Error('Asset Upload Failed');
      }
    }
    return assets;
  }

  private async createAsset(assetControl: AssetControlData): Promise<string> {
    await this.uploadAsset(assetControl);
    let assetCreated: IAsset;
    try {
      assetCreated = await this.assetService.createAsset(assetControl.product.id, assetControl.asset);
    } catch (error) {
      throw new Error('Asset Create Failed');
    }
    return assetCreated.id;
  }

  private async uploadAsset(assetControl: AssetControlData): Promise<void> {
    const uploadPromise = this.handleFileUploadUsingEndpoint(assetControl);
    await uploadPromise;
  }

  private async handleFileUploadUsingEndpoint(assetControl: AssetControlData): Promise<void> {
    const newFile = new File([assetControl.file], assetControl.asset.name, {type: assetControl.file.type});
    const boombitId = assetControl.product.boombitId;
    await this.assetService.uploadFile(newFile, boombitId);
    assetControl.asset.url = this.imageDataService.getFileSrcUrl(assetControl.asset.name, boombitId);
  }


  private getExistingAssetsSplit(): IAdUnitFormatAssetPair[] {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const pairs: IAdUnitFormatAssetPair[] = [];
    formGroup.controls.splitScreenSections.controls.forEach(group => {
      group.controls.assetControls.controls.forEach(assetControl => {
          pairs.push({
            assetId: assetControl.controls.asset?.value?.id,
            adUnitFormatId: assetControl.controls.adUnitFormat.value.id
          });
        }
      );
    });
    return pairs.filter(el => el.assetId);
  }

  private async createNewAssetsPresignedForSplit(): Promise<IAdUnitFormatAssetPair[]> {
    const assets: IAdUnitFormatAssetPair[] = [];
    const newAssetControls = this.getNewAssetsControlsSplit();
    await this.handleFileUploadUsingPresignedUrls(newAssetControls);

    for (const assetControl of newAssetControls) {
      try {
        const assetId = await this.createAssetPresigned(assetControl);
        assets.push({assetId, adUnitFormatId: assetControl.adUnitFormat.id});
      } catch (error) {
        this.loadingSubject.next(false);
        throw new Error('Asset Creation Failed');
      }
    }
    return assets;
  }

  private async handleFileUploadUsingPresignedUrls(assetControls: AssetControlData[]): Promise<void> {
    const payload: PresignedUrlPayload[] = assetControls.map(assetControl =>
      ({
        originalName: assetControl.file.name,
        boombitId: assetControl.product.boombitId,
        contentType: assetControl.file.type
      }));
    const presignedUrlResponse = await this.assetService.generatePresignedUrls(payload);

    for (const assetControl of assetControls) {
      const fileuploadurl = presignedUrlResponse[assetControl.file.name]?.url;
      const newFileName = presignedUrlResponse[assetControl.file.name].generatedName + '.' + assetControl.file.name.split('.').pop();
      const newFile = new File([assetControl.file], newFileName, {type: assetControl.file.type});
      await firstValueFrom(this.fileUploadService.uploadFileAWSS3(fileuploadurl, assetControl.file.type, newFile));
      assetControl.asset.url = presignedUrlResponse[assetControl.file.name]?.assetUrl;
    }
  }

  private async createAssetPresigned(assetControl: AssetControlData): Promise<string> {
    let assetCreated: IAsset;
    try {
      assetCreated = await this.assetService.createAsset(assetControl.product.id, assetControl.asset);
    } catch (error) {
      throw new Error('Asset Create Failed');
    }
    return assetCreated.id;
  }

  private getNewAssetsControlsSplit(): AssetControlData[] {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const values: AssetControlData[] = [];
    formGroup.controls.splitScreenSections.controls.forEach(group => {
      group.controls.assetControls.controls.forEach(assetControl => {
        if (assetControl.controls.asset?.value && !assetControl.controls.asset?.value?.id) {
          values.push({...assetControl.value, product: group.controls.product?.value} as AssetControlData);
        }
      });
    });
    return values;
  }

  private async getCreativeRawRegular(): Promise<ICreativeRaw> {
    const formGroup = this.creativeFormStateService.getFormGroup();
    let newAssets: IAdUnitFormatAssetPair[];
    if (this.fileUploadService.canUploadUsingPresignedUrls()) {
      newAssets = await this.createNewAssetsPresigned();
    } else {
      newAssets = await this.createNewAssets();
    }
    const existingAssets = this.getExistingAssets();
    return this.getCreativeRawObj(formGroup, newAssets, existingAssets);
  }

  private getCreativeRawObj(formGroup: FormGroup<ICreativeFormGroup>,
                            newAssets: IAdUnitFormatAssetPair[], existingAssets: IAdUnitFormatAssetPair[]): ICreativeRaw {
    const formValue = formGroup.value;
    return {
      category: formValue.adUnit.type,
      name: formValue.name,
      productId: formValue.product.id,
      adUnitId: formValue.adUnit.id,
      assetIds: existingAssets.concat(newAssets).map(el => el.assetId),
      matchedAssetsToFormats: existingAssets.concat(newAssets),
      description: formValue.description,
      incentiveText: formValue.incentiveText,
      useStaticForDefaultHtml: this.getUseDefaultStaticUrl(formGroup),
    };
  }

  private getUseDefaultStaticUrl(formGroup: FormGroup<ICreativeFormGroup>): boolean {
    const formValue = formGroup.value;
    return this.creativeControlsService.isRewardedOrInterstitial() && formValue.isStatic &&
      formValue.useStaticForDefaultHtml;
  }

  private getExistingAssets(): IAdUnitFormatAssetPair[] {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const pairs: IAdUnitFormatAssetPair[] = [];
    formGroup.controls.regularAssets.controls.forEach(group => {
      pairs.push({assetId: group.controls.asset?.value?.id, adUnitFormatId: group.controls.adUnitFormat.value.id});

    });
    return pairs.filter(el => el.assetId);
  }

  private async createNewAssets(): Promise<IAdUnitFormatAssetPair[]> {
    const assets: IAdUnitFormatAssetPair[] = [];
    for (const assetControl of this.getNewAssetsControls()) {
      try {
        const assetId = await this.createAsset(assetControl);
        assets.push({assetId, adUnitFormatId: assetControl.adUnitFormat.id});
      } catch (error) {
        this.loadingSubject.next(false);
        throw new Error('Asset Upload Failed');
      }
    }
    return assets;
  }

  private async createNewAssetsPresigned(): Promise<IAdUnitFormatAssetPair[]> {
    const assets: IAdUnitFormatAssetPair[] = [];
    const newAssetControls = this.getNewAssetsControls();
    await this.handleFileUploadUsingPresignedUrls(newAssetControls);

    for (const assetControl of newAssetControls) {
      try {
        const assetId = await this.createAssetPresigned(assetControl);
        assets.push({assetId, adUnitFormatId: assetControl.adUnitFormat.id});
      } catch (error) {
        this.loadingSubject.next(false);
        throw new Error('Asset Creation Failed');
      }
    }
    return assets;
  }

  private getNewAssetsControls(): AssetControlData[] {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const values: AssetControlData[] = [];
    formGroup.controls.regularAssets.controls.forEach(group => {
      if (group.controls.asset?.value && !group.controls.asset?.value?.id) {
        values.push({...group.value, product: formGroup.controls.product.value} as AssetControlData);
      }
    });
    return values;
  }

  public destroy(): void {
    this.loadingSubject = new Subject<boolean>();
  }
}
