import { Injectable } from '@angular/core';
import {ICreativeDetails} from '../interface/ICreativeDetails';
import {ICreativeFormGroup} from '../interface/ICreativeFormGroup';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CreativeFormStateService {

  private initialValue: ICreativeDetails;
  private isEditMode = false;
  private formGroup: FormGroup<ICreativeFormGroup>;

  constructor() { }

  public initValue(initialValue: ICreativeDetails): void {
    this.initialValue = initialValue;
  }

  public initEditMode(isEditMode: boolean): void {
    this.isEditMode = isEditMode;
  }

  public initFormGroup(formGroup: FormGroup<ICreativeFormGroup>): void {
    this.formGroup = formGroup;
  }

  public getInitialValue(): ICreativeDetails {
    if (!this.initialValue && this.isEditMode) {
      console.error('Initial value is not set for edit mode');
    }
    return this.initialValue;
  }

  public getIsEditMode(): boolean {
    return this.isEditMode;
  }

  public getFormGroup(): FormGroup<ICreativeFormGroup> {
    if (!this.formGroup) {
      console.error('Form group is not set');
    }
    return this.formGroup;
  }

  public destroy(): void {
    this.initialValue = null;
    this.isEditMode = false;
    this.formGroup = null;
  }
}
