import {Injectable} from '@angular/core';
import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import _ from 'lodash';
import {FormGroup} from '@angular/forms';
import {AssetControl} from '../interface/ICreativeFormGroup';
import {IAsset} from '../../asset/interface/IAsset';
import {Status} from '../../shared/enum/Status';
import {RewardedAdUnitFormat} from '../../ad-unit/enum/RewardedAdUnitFormat';
import {InterstitialAdUnitFormat} from '../../ad-unit/enum/InterstitialAdUnitFormat';
import {InteractiveIconsAdUnitFormat} from '../../ad-unit/enum/InteractiveIconsAdUnitFormat';
import {BannerAdUnitFormat} from '../../ad-unit/enum/BannerAdUnitFormat';
import {EmbeddedAdUnitFormat} from '../../ad-unit/enum/EmbeddedAdUnitFormat';
import {MoreGamesAdUnitFormat} from '../../ad-unit/enum/MoreGamesAdUnitFormat';

@Injectable({
  providedIn: 'root'
})
/**
 * Service for handling creative form.
 * Some methods from the service rely on initialValue from CreativeFormStateService.
 * That is why CreativeFormComponent initializes CreativeFormStateService first and then methods from the CreativeFormService can be executed.
 */
export class CreativeFormUtilService {
  static readonly adUnitFormatsOrder = {
    interactiveIcons: [InteractiveIconsAdUnitFormat.SMALL, InteractiveIconsAdUnitFormat.BIG],
    bannerAdUnit: [BannerAdUnitFormat.SD, BannerAdUnitFormat.IPAD, BannerAdUnitFormat.HD],
    embeddedAdUnit: [EmbeddedAdUnitFormat.SD, EmbeddedAdUnitFormat.HD],
    moreGamesAdUnit: [MoreGamesAdUnitFormat.SQUARE, MoreGamesAdUnitFormat.BANNER, MoreGamesAdUnitFormat.VIDEO,
      MoreGamesAdUnitFormat.VIDEO_LANDSCAPE, MoreGamesAdUnitFormat.PLAYABLE],
    interstitialAdUnit: [InterstitialAdUnitFormat.VIDEO, InterstitialAdUnitFormat.VIDEO_SMALL,
      InterstitialAdUnitFormat.HTML, InterstitialAdUnitFormat.VIDEO_LANDSCAPE, InterstitialAdUnitFormat.VIDEO_LANDSCAPE_SMALL,
      InterstitialAdUnitFormat.HTML_LANDSCAPE, InterstitialAdUnitFormat.STATIC],
    rewardedAdUnit: [RewardedAdUnitFormat.VIDEO, RewardedAdUnitFormat.VIDEO_SMALL, RewardedAdUnitFormat.HTML,
      RewardedAdUnitFormat.VIDEO_LANDSCAPE, RewardedAdUnitFormat.VIDEO_LANDSCAPE_SMALL,
      RewardedAdUnitFormat.HTML_LANDSCAPE, RewardedAdUnitFormat.STATIC]
  };

  readonly elementsPerRow = {
    interactiveIcons: 2,
    bannerAdUnit: 3,
    embeddedAdUnit: 2,
    moreGamesAdUnit: 2,
    interstitialAdUnit: 2,
    rewardedAdUnit: 2
  };

  constructor() {
  }

  public static isFormatLandscape(adUnitFormat: IAdUnitFormat): boolean {
    return adUnitFormat.name.toLowerCase().includes('landscape');
  }

  public static getFormatsSorted(adUnitFormats: IAdUnitFormat[], adUnitName: string): IAdUnitFormat[] {
    if (_.isEmpty(adUnitFormats)) {
      return [];
    }
    const suggestedOrder = this.adUnitFormatsOrder[adUnitName];
    if (!suggestedOrder) {
      return adUnitFormats;
    }

    const orderMap = new Map<string, number>(suggestedOrder.map((item, index) => [item, index]));

    return [...adUnitFormats].sort((a, b) => {
      const indexA: number = orderMap.has(a.name) ? orderMap.get(a.name) : Infinity;
      const indexB: number = orderMap.has(b.name) ? orderMap.get(b.name) : Infinity;

      if (indexA !== indexB) {
        return indexA - indexB;
      }
      return adUnitFormats.indexOf(a) - adUnitFormats.indexOf(b);
    });
  }

  public getColumnsCount(adUnitName: string): number {
    return this.elementsPerRow[adUnitName] || 2;
  }

  public isSameAsset(oldAsset: IAsset, newAsset: IAsset): boolean {
    if (!oldAsset || !oldAsset?.id) {
      return false;
    } else {
      return oldAsset.id === newAsset?.id;
    }
  }

  public getStatus(assetGroup: FormGroup<AssetControl>): Status {
    if (assetGroup.valid && assetGroup.controls.asset.value) {
      return Status.SUCCESS;
    } else if (assetGroup.valid) {
      return null;
    } else if (assetGroup.pending) {
      return null;
    } else if (!assetGroup.controls.asset.value) {
      return null;
    } else {
      return Status.ERROR;
    }
  }
}
