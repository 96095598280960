<app-base-side-dialog [backAction]="backAction" [showBackButton]="!!backAction?.backActionDialogConfig" [dialogRef]="dialogRef">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="preview-content" *ngIf="creative">
    <div class="text-light side-dialog--padding-horizontal preview-header">
      <p class="mat-body-2 color-dark-grey-fourth">{{'creative.creativePreview.creativePreview' | translate}}</p>

      <div class="side-dialog-header-row">

        <h1>{{creative?.name}}
          <fa-icon *ngIf="isSplitScreen" [appIconTooltip]="faArrowUpRightFromSquare"
                   (click)="openSplitScreenPreview()" class="color-primary cursor-pointer p-5"
                   [icon]="faArrowUpRightFromSquare">
          </fa-icon>
        </h1>
      </div>

        <div class="side-dialog-header-row">
        <p>
          {{'adUnit.adUnit' | translate}}: <span>{{creative?.adUnit?.name}}</span>
        </p>
        <p>
          {{'product.productForm.productName' | translate}}:

          <ng-container *ngIf="isSplitScreen">
            <ng-container *ngFor="let product of creative?.splitScreenProducts;let i = index">
              <span>
                <app-split-screen-icon [count]="creative?.splitScreenProducts?.length" [index]="i"></app-split-screen-icon>
                {{product?.name}}
                {{i !== creative?.splitScreenProducts?.length - 1 ? ', ' : ''}}
              </span>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!isSplitScreen">
            {{creative?.product?.name}}
          </ng-container>
        </p>
        <p *ngIf="isStatic">
          {{'creative.creativeForm.useDefaultHtml' | translate}}: {{creative?.useStaticForDefaultHtml ? 'yes' : 'no'}}
        </p>
          <ng-container *ngIf="isMoreGames()">
            <p>
              {{'creative.creativeForm.description' | translate}}
              <fa-icon class="color-primary" [icon]="faCircleInfo"
                       [appTooltip]="'creative.creativeForm.descriptionTooltip' | translate">
            </fa-icon>:
              {{creative?.description || '-'}}
            </p>
            <p>
              {{'creative.creativeForm.incentiveText' | translate}}
              <fa-icon class="color-primary" [icon]="faCircleInfo"
                       [appTooltip]="'creative.creativeForm.incentiveTextTooltip' | translate">
              </fa-icon>
              : {{creative?.incentiveText || '-'}}
            </p>
          </ng-container>
        </div>
    </div>
    <hr class="separator separator--no-margin"/>
    <div *ngIf="!isSplitScreen" class="preview-body--scrollable text-light side-dialog--padding-horizontal">
      <div *ngFor="let asset of creative?.assets; let i = index" class="preview-row--separated">
        <p class="align-center-gap-5">{{'asset.assetPreview.name' | translate}}: {{asset.originalName}}
          <fa-icon [appIconTooltip]="faArrowUpRightFromSquare"
                   (click)="onOpenAssetClicked(asset)" class="color-primary cursor-pointer p-5"
                   [icon]="faArrowUpRightFromSquare">
          </fa-icon>
        </p>
        <p>{{'asset.assetPreview.adUnitFormat' | translate}}: {{adUnitFormatNames[i]}}</p>
        <p>{{'asset.assetPreview.type' | translate}}: {{getAssetType(asset) | async}}</p>
        <p>{{'asset.assetPreview.format' | translate}}: {{asset.format}}</p>
        <p>{{'asset.assetPreview.resolution' | translate}}: {{getResolution(asset.resolution, asset.ratio)}}</p>
        <p>{{'asset.assetPreview.ratio' | translate}}: {{asset.ratio}}</p>
        <p>{{'asset.assetPreview.size' | translate}}: {{asset.size | fileSize}}</p>
        <p *ngIf="asset?.duration > 0">{{'asset.assetPreview.duration' | translate}}: {{asset.duration + 's'}}</p>
        <hr>
      </div>


    <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    </div>
    <div class="split-screen-wrapper side-dialog--padding-horizontal text-light" *ngIf="isSplitScreen">
      <div class="split-screen-table" [ngClass]="{'split-screen-table__2': creative?.splitScreenProducts?.length === 2,
      'split-screen-table__4': creative?.splitScreenProducts?.length !== 2}">
      <ng-container *ngFor="let product of creative?.splitScreenProducts; let i = index">
        <div class="split-screen-item">
          <p class="text-regular" style="padding-bottom: 10px">{{'product.productForm.productName' | translate}}:
          <app-split-screen-icon [count]="creative?.splitScreenProducts?.length" [index]="i"></app-split-screen-icon>
            {{product?.name}}
          </p>
          <ng-container *ngFor="let asset of getAssetsForProduct(product); let j = index; let last = last">
            <p style="white-space: pre-wrap;
      word-break: break-all;" class="align-center-gap-5">{{'asset.assetPreview.name' | translate}}: {{asset.originalName}}
              <fa-icon [appIconTooltip]="faArrowUpRightFromSquare"
                       (click)="onOpenAssetClicked(asset)" class="color-primary cursor-pointer p-5"
                       [icon]="faArrowUpRightFromSquare">
              </fa-icon>
            </p>
            <p>{{'asset.assetPreview.adUnitFormat' | translate}}: {{getAssetFormatNames(asset)}}</p>
            <p>{{'asset.assetPreview.type' | translate}}: {{getAssetType(asset) | async}}</p>
            <p>{{'asset.assetPreview.format' | translate}}: {{asset.format}}</p>
            <p>{{'asset.assetPreview.resolution' | translate}}: {{getResolution(asset.resolution, asset.ratio)}}</p>
            <p>{{'asset.assetPreview.ratio' | translate}}: {{asset.ratio}}</p>
            <p>{{'asset.assetPreview.size' | translate}}: {{asset.size | fileSize}}</p>
            <p *ngIf="asset?.duration > 0">{{'asset.assetPreview.duration' | translate}}: {{asset.duration + 's'}}</p>
            <hr *ngIf="!last"/>
          </ng-container>
        </div>
      </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    </div>
  </div>
</app-base-side-dialog>


<ng-template #actionButtons>
  <div class="side-dialog-action-buttons">
    <button (click)="onCloseClicked()" mat-stroked-button color="primary">
      <fa-icon [icon]="faXMark" class="close-icon-big"></fa-icon>
      {{'button.close' | translate}}
    </button>
    <button (click)="onDeleteCreativeClicked()" mat-flat-button color="primary">
      <fa-icon [icon]="faTrash"></fa-icon>
      {{'creative.deleteCreativeButton' | translate}}
    </button>
    <button (click)="onEditCreativeClicked()" mat-flat-button color="primary">
      <fa-icon [icon]="faPenToSquare"></fa-icon>
      {{'creative.editCreativeButton' | translate}}
    </button>
  </div>
</ng-template>
