import {Component, Input} from '@angular/core';
import {faCorner} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-split-screen-icon',
  templateUrl: './split-screen-icon.component.html',
  styleUrl: './split-screen-icon.component.scss'
})
export class SplitScreenIconComponent {
  @Input() public count: number;
  @Input() public index: number;

  protected readonly faCorner = faCorner;

}
