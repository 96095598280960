<ng-container *ngIf="count === 4">
  <fa-icon *ngIf="index === 0" flip="horizontal" [icon]="faCorner"></fa-icon>
  <fa-icon *ngIf="index === 1" [icon]="faCorner"></fa-icon>
  <fa-icon *ngIf="index === 2" flip="both" [icon]="faCorner"></fa-icon>
  <fa-icon *ngIf="index === 3" flip="vertical" [icon]="faCorner"></fa-icon>
</ng-container>
<ng-container *ngIf="count == 2">
          <span>
          <fa-icon class="txt-3-regular" *ngIf="index === 0" flip="horizontal" [icon]="faCorner"></fa-icon>
          <fa-icon class="txt-3-regular" *ngIf="index === 0" [icon]="faCorner"></fa-icon>
          </span>
  <span>
          <fa-icon class="txt-3-regular" *ngIf="index === 1" flip="both" [icon]="faCorner"></fa-icon>
          <fa-icon class="txt-3-regular" *ngIf="index === 1" flip="vertical" [icon]="faCorner"></fa-icon>
          </span>
</ng-container>
