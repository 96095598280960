import { Injectable } from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import {CreativeFormStateService} from './creative-form-state.service';
import {IAdUnit} from '../../ad-unit/interface/IAdUnit';
import {CreativeFormUtilService} from './creative-form-util.service';
import {ICreativeDetails} from '../interface/ICreativeDetails';

@Injectable({
  providedIn: 'root'
})
export class CreativeFormInitService {

  constructor(private creativeStateService: CreativeFormStateService) { }

  public init(): void {

  }

  /**
   * This method is used to add frontendId to assets and populate the assetFormatValidated,
   * so that initial assets for edit are not validated
   */
  public setFrontendIds(): Set<string> {
    const initialValue = this.creativeStateService.getInitialValue();
    const frontendIds = new Set<string>();
    if (this.creativeStateService.getIsEditMode() && initialValue?.assets) {
      initialValue.assets.forEach(asset => {
        asset.frontendId = uuidv4();
        frontendIds.add(asset.frontendId);
      });
    }
    return frontendIds;
  }

  public isSplitScreenEdit(): boolean {
    return this.creativeStateService.getInitialValue()?.splitScreenProducts?.length > 0;
  }

  public isSplitScreenPortraitEdit(adUnits: IAdUnit[]): boolean {
    const initialValue = this.creativeStateService.getInitialValue();
    if (!this.isSplitScreenEdit()) {
      return false;
    }

    const adUnitFormatIds = initialValue?.matchedAssetsToFormats?.map(el => el.adUnitFormatId);
    if (!adUnitFormatIds || adUnitFormatIds?.length === 0) {
      return false;
    }

    return adUnits.some(adUnit => adUnit.adUnitFormats.some(adUnitFormat =>
      adUnitFormatIds.includes(adUnitFormat.id) && !CreativeFormUtilService.isFormatLandscape(adUnitFormat)));
  }

  public isSplitScreenLandscapeEdit(adUnits: IAdUnit[]): boolean {
    const initialValue = this.creativeStateService.getInitialValue();
    if (!this.isSplitScreenEdit()) {
      return false;
    }

    const adUnitFormatIds = initialValue?.matchedAssetsToFormats?.map(el => el.adUnitFormatId);
    if (!adUnitFormatIds || adUnitFormatIds?.length === 0) {
      return false;
    }

    return adUnits.some(adUnit => adUnit.adUnitFormats.some(adUnitFormat =>
      adUnitFormatIds.includes(adUnitFormat.id) && CreativeFormUtilService.isFormatLandscape(adUnitFormat)));
  }

  public isSplitScreenWith4ElementsEdit(): boolean {
    const initialValue = this.creativeStateService.getInitialValue();
    return this.isSplitScreenEdit() && initialValue?.splitScreenProducts?.length === 4;
  }

  public getIsStaticInitial(adUnits: IAdUnit[]): boolean {
    return this.creativeStateService.getIsEditMode() && this.isInitialCreativeStatic(adUnits);
  }

  private isInitialCreativeStatic(adUnits: IAdUnit[]): boolean {
    const initialValue = this.creativeStateService.getInitialValue();
    const adUnitFormatIds = initialValue.matchedAssetsToFormats.map(matched => matched.adUnitFormatId);
    const allAdUnitFormats = [];
    adUnits.forEach(adUnit => {
      allAdUnitFormats.push(...adUnit.adUnitFormats);
    });
    const adUnitFormats = allAdUnitFormats.filter(adUnitFormat => adUnitFormatIds.includes(adUnitFormat.id));
    return adUnitFormats.some(adUnitFormat => adUnitFormat.isStatic);
  }

  public getUseStaticForDefaultHtml(): boolean {
    return this.creativeStateService.getIsEditMode() && this.creativeStateService.getInitialValue()?.useStaticForDefaultHtml;
  }

  public destroy(): void {

  }
}
