export enum SplitScreenPlacement {
  LEFT_UPPER = 'LEFT_UPPER',
  LEFT_LOWER = 'LEFT_LOWER',
  RIGHT_UPPER = 'RIGHT_UPPER',
  RIGHT_LOWER = 'RIGHT_LOWER'
}


export enum SplitScreenSmallPlacement {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}
