import {Injectable} from '@angular/core';
import {IAdUnit} from '../../ad-unit/interface/IAdUnit';
import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import {InterstitialAdUnitFormat} from '../../ad-unit/enum/InterstitialAdUnitFormat';
import {RewardedAdUnitFormat} from '../../ad-unit/enum/RewardedAdUnitFormat';
import {CreativeFormUtilService} from './creative-form-util.service';

@Injectable({
  providedIn: 'root'
})
export class CreativeSplitFormService {

  constructor() {
  }

  public getAdFormatsForSplitScreen(adUnit: IAdUnit, data: {
                                      isLandscape: boolean,
                                      isPortrait: boolean,
                                      is4Elements: boolean
                                    },
                                    isInterstitial: boolean): IAdUnitFormat[] {
    const adUnitFormats: IAdUnitFormat[] = [];
    if (isInterstitial) {
      if (data.is4Elements) {
        if (data.isPortrait) {
          const adUnitFormatNames: string[] = [InterstitialAdUnitFormat.HTML, InterstitialAdUnitFormat.VIDEO];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        if (data.isLandscape) {
          const adUnitFormatNames: string[] = [InterstitialAdUnitFormat.HTML_LANDSCAPE, InterstitialAdUnitFormat.VIDEO_LANDSCAPE];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        return CreativeFormUtilService.getFormatsSorted(adUnitFormats, adUnit.name);
      } else {
        if (data.isPortrait) {
          const adUnitFormatNames: string[] = [InterstitialAdUnitFormat.HTML, InterstitialAdUnitFormat.VIDEO_SMALL];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        if (data.isLandscape) {
          const adUnitFormatNames: string[] = [InterstitialAdUnitFormat.HTML_LANDSCAPE, InterstitialAdUnitFormat.VIDEO_LANDSCAPE_SMALL];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        return CreativeFormUtilService.getFormatsSorted(adUnitFormats, adUnit.name);
      }
    } else {
      if (data.is4Elements) {
        if (data.isPortrait) {
          const adUnitFormatNames: string[] = [RewardedAdUnitFormat.HTML, RewardedAdUnitFormat.VIDEO];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        if (data.isLandscape) {
          const adUnitFormatNames: string[] = [RewardedAdUnitFormat.HTML_LANDSCAPE, RewardedAdUnitFormat.VIDEO_LANDSCAPE];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        return CreativeFormUtilService.getFormatsSorted(adUnitFormats, adUnit.name);
      } else {
        if (data.isPortrait) {
          const adUnitFormatNames: string[] = [RewardedAdUnitFormat.HTML, RewardedAdUnitFormat.VIDEO_SMALL];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        if (data.isLandscape) {
          const adUnitFormatNames: string[] = [RewardedAdUnitFormat.HTML_LANDSCAPE, RewardedAdUnitFormat.VIDEO_LANDSCAPE_SMALL];
          const formats = adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormatNames.includes(adUnitFormat.name));
          adUnitFormats.push(...formats);
        }
        return CreativeFormUtilService.getFormatsSorted(adUnitFormats, adUnit.name);
      }
    }

  }
}
