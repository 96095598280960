import {FormArray, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {IAsset} from '../../asset/interface/IAsset';
import {AssetControl, SplitScreenSectionGroup} from '../interface/ICreativeFormGroup';
import {ProductSimple} from '../../product/interface/IProductSimple';

export function matchesResolutionValidator(control: FormControl<IAsset>): ValidationErrors | null {
  if (!control?.value || !control.parent) {
    return null;
  } else {
    return matchesResolutionAndRatio(control) ? null : {resolutionError: true};
  }
}

function matchesResolutionAndRatio(control: FormControl<IAsset>): boolean {
  const group = control.parent as FormGroup<AssetControl>;
  if (group.value.adUnitFormat.ratio) {
    return group.value.adUnitFormat.ratio === control.value.ratio &&
      group.value.adUnitFormat.resolution?.width >= control.value.resolution?.width &&
      group.value.adUnitFormat.resolution?.height >= control.value.resolution?.height;
  } else {
    return group.value.adUnitFormat.resolution?.width >= control.value.resolution?.width &&
      group.value.adUnitFormat.resolution?.height >= control.value.resolution?.height;
  }
}

export function matchesRatioValidator(control: FormControl<IAsset>): ValidationErrors | null {
  if (!control?.value || !control.parent) {
    return null;
  } else {
    const group = control.parent as FormGroup<AssetControl>;
    const valid = group.controls.adUnitFormat.value.ratio ? group.controls.adUnitFormat.value.ratio === control.value?.ratio : true;
    return valid ? null : {ratioError: true};
  }
}

export function matchesFileFormatValidator(control: FormControl<IAsset>): ValidationErrors | null {
  if (!control?.value || !control.parent) {
    return null;
  } else {
    const group = control.parent as FormGroup<AssetControl>;
    const valid = group.controls.adUnitFormat.value.formats.includes(control.value.format);
    return valid ? null : {fileFormatError: true};
  }
}

export function matchesMaxSizeValidator(control: FormControl<IAsset>): ValidationErrors | null {
  if (!control?.value || !control.parent) {
    return null;
  } else {
    const group = control.parent as FormGroup<AssetControl>;
    const valid = control.value.size <= group.controls.adUnitFormat.value.maxSize;
    return valid ? null : {maxSizeError: true};
  }
}

export function matchesMaxDurationValidator(control: FormControl<IAsset>): ValidationErrors | null {
  if (!control?.value || !control.parent) {
    return null;
  } else {
    const group = control.parent as FormGroup<AssetControl>;
    const valid = control.value.duration <= group.controls.adUnitFormat.value.maxDuration;
    return valid ? null : {maxDurationError: true};
  }
}

export function productUniqueValidator(control: FormControl<ProductSimple>): ValidationErrors | null {
  if (!control?.value || !control?.parent?.parent) {
    return null;
  } else {
    const array = control.parent.parent as FormArray<FormGroup<SplitScreenSectionGroup>>;
    const products = array.controls.map((group: FormGroup<SplitScreenSectionGroup>) => group.controls.product.value);
    const valid = products.filter((product: ProductSimple) => product?.id === control.value?.id).length === 1;
    return valid ? null : {productDuplicationError: true};
  }
}
